@import './variables';
@import './colors';

.vw-100 {
    width: 100vw !important;
}

.vh-100 {
    height: 100vh !important;
}

.w-100 {
    width: 100% !important;
}

.h-100 {
    height: 100% !important;
}

.margin-0 {
    margin: 0% !important;
}

.padding-1 {
    padding: 0.5rem !important;
}

.padding-2 {
    padding: 1rem !important;
}

.padding-3 {
    padding: 1.5rem !important;
}

.paddingX-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.paddingX-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.paddingX-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}

.padding-top-1 {
    padding-top: 0.5rem !important;
}

.padding-top-2 {
    padding-top: 1rem !important;
}

.padding-top-3 {
    padding-top: 1.5rem !important;
}

.padding-bottom-1 {
    padding-bottom: 0.5rem !important;
}

.padding-bottom-2 {
    padding-bottom: 1rem !important;
}

.padding-bottom-3 {
    padding-bottom: 1.5rem !important;
}

.paddingY-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
}

.paddingY-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.paddingY-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
}

.paddingY-4 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.rounded-1 {
    border-radius: 0.5rem !important;
}

.rounded-2 {
    border-radius: 0.75rem !important;
}

.rounded-3 {
    border-radius: 1.5rem !important;
}

.rounded-top-1 {
    border-top-left-radius: 0.5rem !important;
    border-top-right-radius: 0.5rem !important;
}

.rounded-top-2 {
    border-top-left-radius: 0.75rem !important;
    border-top-right-radius: 0.75rem !important;
}

.rounded-bottom-1 {
    border-bottom-left-radius: 0.5rem !important;
    border-bottom-right-radius: 0.5rem !important;
}

.rounded-bottom-2 {
    border-bottom-left-radius: 0.75rem !important;
    border-bottom-right-radius: 0.75rem !important;
}

.y-auto {
    overflow-y: auto !important;
}

.x-auto {
    overflow-x: auto !important;
}

.shadow {
    box-shadow: $shadow !important;
}

.bg-primary-light {
    background-color: $light-primary !important;
}

.bg-secondary-light {
    background-color: $light-secondary !important;
}

.bg-white {
    background-color: $white !important;
}

.bg-gray {
    background-color: $gray !important;
}

.flex {
    display: flex !important;
}

.gap-1 {
    gap: 1rem !important;
}

.text-center {
    text-align: center !important;
}

.cursor-pointer {
    cursor: pointer !important;
}

.ant-popover-inner {
    width: 15.625rem !important;
}

.border-light-green {
    border: 1px solid $light-green;
}
.d-block{
    display: block;
}