.container {
	max-width: 90rem;
	margin: 0 auto;
	width: 100%;
	padding-left: 1rem;
	padding-right: 1rem;

    @media only screen and (max-width: 1600px){
        max-width: 71.25rem;
    }
    @media only screen and (max-width: 1200px){
        max-width: 60rem;
    }
    @media only screen and (max-width: 992px){
        max-width: 45rem;
    }
    @media only screen and (max-width: 768px){
        max-width: 100%;
    }
}
.container-fluid{
    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
}
