.general-settings {
    .ant-upload-list-item-container {
        width: 9rem !important;
        height: 9rem !important;
    }

    .ant-upload-list-item-action {
        &:hover {
            background: transparent !important;
        }
    }

    .ant-upload-select {
        width: 9rem !important;
        height: 9rem !important;
    }
}