.ant-menu {
    .ant-menu-item {
        .ant-menu-item-icon {
            min-width: 1.80rem !important;
        }
    }
}

.ant-menu-title-content {
    padding: 0 1.25rem;
}

.side-bar-lable-padding {
    padding: 0 1.5rem;
}

.sidebar-menu {
    .ant-menu-submenu-disabled {
        .ant-menu-submenu-title {
            &:hover {
                background-color: transparent !important;
            }
        }
    }
}