@font-face {
  font-family: 'Nunito';
  font-style: normal;
  font-weight: normal;
  src: local('Nunito'), local('Nunito'), url('./assets/fonts/Nunito/Nunito-Regular.ttf') format('truetype');
}

body,
*,
*::after,
*::before {
  margin: 0%;
  padding: 0%;
  line-height: 1;
  font-family: "Nunito", sans-serif;
  box-sizing: border-box;
}

// Custom Scrollbar //
::-webkit-scrollbar {
  width: 0.25rem;
  height: 0.25rem;
}

::-webkit-scrollbar-track {
  background: #FFFFFF;
}

::-webkit-scrollbar-thumb {
  background: #007144;
  border-radius: 0 0.625rem 0.625rem 0;
}

::-webkit-scrollbar-thumb:hover {
  background: darken(#007144, 5%);
  border-radius: 0 0.625rem 0.625rem 0;
}