.ant-dropdown {
    min-width: 8.5rem !important;

    .ant-dropdown-menu {
        margin-top: 1rem !important;
    }

    .language-dropdown {
        margin-top: -0.5rem !important;
    }
}

.language-item {
    height: 2rem;
}