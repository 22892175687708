$primary: #007144;
$secondary: #a20417;
$light-primary: #e5f1ec;
$light-secondary: #ffdfe3;
$danger: #D71313;
$success: #27C93F;
$light-green:#99C6B4;
$warning: #FFBD2E;
$info: #0dcaf0;
$dark: #1C2455;
$muted: #6C757D;
$white: #FFFFFF;
$black: #000000;
$gray: #F2F2F2;