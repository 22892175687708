$primary: #007144;
$secondary: #a20417;
$light-primary: #e5f1ec;
$light-secondary: #ffdfe3;
$black: #000000;

.success-notification {
    border: 1px solid $primary !important;
    border-radius: 0.5rem !important;
    padding: 0.75rem 1rem !important;
    background: $light-primary !important;

    .notification-divider {
        border-color: $black !important;
    }

    .ant-divider-vertical {
        height: 1.5rem !important;
        margin-inline: 1rem !important;
    }
}

.ant-notification-notice-message {
    margin-bottom: 0 !important;
}

.ant-notification-notice-close {
    top: 0.9rem !important;
}

.error-notification {
    border: 1px solid $secondary !important;
    border-radius: 0.5rem;
    background: $light-secondary !important;
    padding: 0.75rem 1rem !important;

    .notification-divider {
        border-color: $black;
    }

    .ant-divider-vertical {
        height: 1.5rem !important;
        margin-inline: 1rem !important;
    }
}