@import './styles/root';

body {
    background: $background;
}

.ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
    padding-inline: unset;
    padding-top: 0.25rem;
    padding-left: 1.35rem;
    display: flex;
    align-items: center;
}