.new-vacation {
    .border-right {
        border-right: #EEEEEE;
    }

    .red-color {
        color: #FF0000 !important;
    }

    .yellow-color {
        color: #FF7A00 !important;
    }

    .blue-color {
        color: #0094FF !important;
    }

    .ant-upload-btn {
        padding: 1.5rem !important;
    }

    .vacation-datepicker{
        .ant-picker-selection-item-content{
            line-height: 2.5 !important;
        }
    }
}