.tracking-table {
    .ant-table {
        scrollbar-color: inherit !important;
    }

    .ant-table-cell {
        padding: 4px;
    }

    .ant-btn {
        margin: 8px 0;
    }

    h2 {
        font-size: 1.5rem;
    }

    .ant-table-wrapper .ant-table-cell {
        padding: 2px 16px !important;
    }

}
