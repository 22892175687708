@import '../../utils/colors';

.data-table {
    .ant-table-cell {
        white-space: nowrap;
        overflow: hidden;
    }

    .ant-table {
        scrollbar-color: inherit !important; 
    }

    .ant-pagination {
        margin: 0 !important;
        padding: 1rem 0.5rem;
        background-color: $white;

        .ant-pagination-item {
            a {
                display: inline-block !important;
            }
        }

        .ant-pagination-item-link {
            display: inline-block !important;
        }

        .ant-select-item-option-content{
            line-height: 1.5;
        }

        .ant-pagination-next {
            button {
                color: $white;
                margin-left: 0.5rem;

                @media only screen and (max-width:768px) {
                    font-size: 0.75rem;
                }
            }
        }

        .ant-pagination-simple-pager {
            @media only screen and (max-width:768px) {
                height: 2.25rem !important;
            }
        }

        .ant-pagination-total-text {
            line-height: 2.8 !important;

            @media only screen and (max-width:480px) {
                margin-bottom: 0.5rem;
            }
        }

        .ant-pagination-prev {
            button {
                color: $white;
                margin-right: 0.5rem;

                @media only screen and (max-width:768px) {
                    font-size: 0.75rem;
                }
            }
        }

        .ant-pagination-disabled {
            button {
                color: $black;

                @media only screen and (max-width:768px) {
                    font-size: 0.75rem;
                }
            }
        }

        .ant-pagination-total-text {
            @media only screen and (max-width:768px) {
                padding-left: 1rem;
            }
        }

        .ant-pagination-total-text {
            @media only screen and (max-width:768px) {
                width: 100%;
            }
        }
    }
}